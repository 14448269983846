import bootstrap from '../inits/bootstrap.js';
import { createApp } from 'vue';
import vueConf, { setup } from '../inits/vue-desktop.js';
import desktopLogin from '../views/login/login-desktop.vue';

const app = createApp({
    components: {
        ...vueConf.components,
        desktopLogin,
    },
    store: vueConf.store,
});
bootstrap(app);
setup(app);
app.use(vueConf.store);
app.mount('.sitecontent');
